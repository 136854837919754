import { Header } from "@garantidos/components"

import Footer from "@mobi/ds/components/Footer"

import AppLayout from "layout/AppLayout"

import AccompanyProposal from "../../../../../packages/components/AccompanyProposal"
import BeAClient from "./components/BeAClient"
import Contracting from "./components/Contracting"
import ContractingChannels from "./components/ContractingChannels"
import Easines from "./components/Easines"
import Faq from "./components/Faq"
import Hero from "./components/Hero"
import HomeInsurance from "./components/HomeInsurance"
import Migration from "./components/Migration"
import Modalities from "./components/Modalities"
import Portability from "./components/Portability"
import PreFaq from "./components/PreFaq"
import Quote from "./components/Quote"
import Video from "./components/Video"

import useHome from "./hooks"

import { footerContactInfos } from "./data"

const Home = () => {
  useHome()

  return (
    <>
      <Header
        utmsHeaderOpenAccount="&utm_term=imob-x-lp-header&utm_sou=imob&utm_med=x&utm_camp=lp&utm_cont=header&utm_ter=14"
        utmsHeaderMenuCheckingAccount="&utm_term=imob-x-lp-headerparavocemenu&utm_sou=imob&utm_med=x&utm_camp=lp&utm_cont=headerparavocemenu&utm_ter=43"
        utmsHeaderMenuOpenAccount="&utm_term=imob-x-lp-headerparavocebanner&utm_sou=imob&utm_med=x&utm_camp=lp&utm_cont=headerparavocebanner&utm_ter=44"
      />
      <AppLayout title="Financiamento Imobiliário Itaú">
        <Hero />
        <Easines />
        <HomeInsurance />
        <Contracting />
        <Modalities />
        <PreFaq />
        <ContractingChannels />
        <Portability />
        <Migration />
        <AccompanyProposal />
        <BeAClient />
        {/* <Video /> */}
        <Quote />
        <Faq />
        <Footer contactInfos={footerContactInfos} />
      </AppLayout>
    </>
  )
}

export default Home
