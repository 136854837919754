import Text from "@mobi/ds/components/Text"

const items = [
  {
    title: "Como é calculado o rendimento da poupança?",
    children: (
      <>
        <Text as="p" className="preFaq__text">
          O rendimento da poupança varia de acordo com a movimentação da Selic,
          seguindo a regra de que quando a Selic está menor que 8,50% a poupança
          equivale a 70% do valor da Selic, agora quando ela passa de 8,50% ao
          ano, o rendimento da poupança é travado em 6,17%, e é daí que vem o
          teto da sua taxa.
        </Text>
        <Text as="p" className="preFaq__text">
          O valor da taxa Selic é decidido a cada 45 dias pelo Comitê de
          Política Monetária do Banco Central.
        </Text>
      </>
    )
  },
  {
    title:
      "Como o rendimento da poupança impacta no Crédito Imobiliário com Juros da Poupança?",
    children: (
      <>
        <Text as="p" className="preFaq__text">
          Como a parte variável da taxa corresponde ao rendimento da poupança,
          sempre que essa taxa mudar, a parte variável da taxa também mudará.
        </Text>
        <Text as="p" className="preFaq__text">
          O rendimento da poupança pode diminuir ou aumentar e quando a taxa
          Selic diminui, você paga menos juros e sua parcela fica mais baixa e
          quando a Selic aumenta, impacta seu valor de parcela e para sua
          segurança, há um limite de aumento em sua taxa e parcelas.
        </Text>
      </>
    )
  },
  {
    title:
      "Como é calculado o teto da taxa do Crédito Imobiliário com Juros da Poupança?",
    children: (
      <>
        <Text as="p" className="preFaq__text">
          Caso a Selic esteja menor ou igual à 8,5% ao ano, o rendimento da
          poupança será igual à 70% da Selic, porém, se o valor da taxa a Selic
          estiver maior que 8,5% ao ano, o rendimento da poupança é fixado em
          6,17% ao ano, garantindo um teto na taxa.
        </Text>
        <Text as="p" className="preFaq__text">
          O teto pode ser calculado somando a sua taxa fixa + o rendimento
          máximo da poupança, de 6,17% ao ano. Por exemplo, se sua taxa fixa for
          5,49% ao ano, o teto da usa taxa será de 11,66% ao ano (5,49% ao ano
          da parte fixa + teto do rendimento da poupança de 6,17% ao ano).
        </Text>
      </>
    )
  },
  {
    title:
      "Quais são as principais regras para a utilização do FGTS na compra de um imóvel?",
    children: (
      <>
        <Text as="p" className="preFaq__text">
          <strong> O Imóvel deve: </strong>
        </Text>
        <Text as="p" className="preFaq__text">
          • Ser avaliado no valor de até 1,5 MM;
        </Text>
        <Text as="p" className="preFaq__text">
          • Obrigatoriamente ser imóvel urbano e destinado à residência do
          comprador e/ou de seu cônjuge (cônjuge que estiver fazendo uso do
          FGTS);
        </Text>
        <Text as="p" className="preFaq__text">
          • Estar matriculado no Cartório de Registro de Imóveis;
        </Text>
        <Text as="p" className="preFaq__text">
          • Respeitar o intervalo mínimo de 3 anos da data da última utilização
          do FGTS para compra do imóvel;
        </Text>
        <Text as="p" className="preFaq__text">
          • Estar no mesmo município onde a pessoa trabalhadora exerce sua
          ocupação principal, nos municípios limítrofes a ele ou integrantes da
          mesma região metropolitana; ou no mesmo município onde o cliente
          comprove residir há mais de 1 ano, nos municípios limítrofes a ele ou
          integrantes da mesma região metropolitana.{" "}
        </Text>
        <br /> <br />
        <Text as="p" className="preFaq__text">
          <strong>A pessoa trabalhadora deve:</strong>
        </Text>
        <Text as="p" className="preFaq__text">
          • Possuir 3 anos de trabalho sob o regime do FGTS, somando-se os
          períodos trabalhados, consecutivos ou não, na mesma ou em diferentes
          empresas;
        </Text>
        <Text as="p" className="preFaq__text">
          • Respeitar o intervalo mínimo de 3 anos para a compra de um novo
          imóvel com a utilização do FGTS;
        </Text>
        <br /> <br />
        <Text as="p" className="preFaq__text">
          <strong>A pessoa trabalhadora não pode:</strong>
        </Text>
        <Text as="p" className="preFaq__text">
          • Ser titular de qualquer outro financiamento ativo no âmbito do
          Sistema Financeiro de Habitação (SFH) em qualquer território nacional;
        </Text>
        <Text as="p" className="preFaq__text">
          • Não ser proprietário, possuidor, promitente comprador, usufrutuário
          ou cessionário de outro imóvel residencial, concluído ou em
          construção, localizado:
        </Text>
        <Text as="p" className="preFaq__text">
          • no mesmo município onde exerça sua ocupação de trabalho principal,
          incluindo os municípios limítrofes ou os municípios integrantes da
          mesma Região Metropolitana; ou
        </Text>
        <Text as="p" className="preFaq__text">
          • no mesmo município onde reside, incluindo os municípios limítrofes
          ou os municípios integrantes da mesma Região Metropolitana.
        </Text>
        <br /> <br />
        <Text as="p" className="preFaq__text">
          Em caso de dúvidas, consulte o manual da Caixa Econômica Federal no
          link: <br />
          <a
            className="preFaq__link"
            href="https://www.caixa.gov.br/Downloads/fgts-moradia/MANUAL_DA_MORADIA_PROPRIA_13_01_2023.pdf"
            target="_blank"
            rel="noreferrer"
            aria-label="https://www.caixa.gov.br/Downloads/fgts-moradia/MANUAL_DA_MORADIA_PROPRIA_13_01_2023.pdf"
          >
            https://www.caixa.gov.br/Downloads/fgts-moradia/MANUAL_DA_MORADIA_PROPRIA_13_01_2023.pdf
          </a>
          ou entre em contato com a nossa central de atendimento.
        </Text>
      </>
    )
  }
]

export { items }
