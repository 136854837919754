import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"
import useMediaQuery from "@mobi/hooks/useMediaQuery"

import Img from "components/img"

import HeroForm from "./components/HeroForm"

import useHero from "./hooks"

import "./style.scss"

const Hero = () => {
  const isMobile = useMediaQuery("mobile")
  useHero()
  return (
    <section className="heroAlternative" id="hero">
      <div
        id="hero-image-container"
        className="heroAlternative__imageContainer"
      >
        <If
          condition={!isMobile}
          renderIf={
            <Img
              file="lp-hero-alternativo"
              className="heroAlternative__image"
              alt="Sala de estar com decoração laranja"
              lazy="false"
              fetchpriority="high"
              width="100%"
              height="100%"
            />
          }
          renderElse={
            <Img
              file="lp-hero-alternativo-mobile"
              alt="Sala de estar com decoração laranja"
              className="heroAlternative__image"
              lazy="false"
              fetchpriority="high"
              width="100%"
              height="100%"
            />
          }
        />
      </div>
      <div className="innerContainer heroAlternative__container">
        <div className="heroAlternative__textWrapper">
          <Text as="h1" className="heroAlternative__title">
            <span> financiamento </span>
            <span>imobiliário Itaú</span>
          </Text>
          <Text as="h2" className="heroAlternative__subtitle">
            Até 35 anos para pagar o seu imóvel.
          </Text>
        </div>
        <div className="heroAlternative__formWrapper">
          <HeroForm />
        </div>
      </div>
    </section>
  )
}

export default Hero
